import React, { createContext, PropsWithChildren, useState } from "react";
import { ChallengeType, ContactSubjectType } from "../../types/BlogTypes";
import { POST_XHTTP } from "../xhttps";
import { MailBodyType } from "../../types/UITypes";
import {
	bracketSizes,
	contactChallengeRanges,
	ContactSector,
	readableChallengeType,
	hubspotOfferTypes,
	SalesmenAddress,
	SalesmenHubspotId,
	internalChallengeSectorsWithSalesmen,
	internalChallengeSectorsWithSalesmenHubspotId,
	interCompanyChallengeSectorsWithSalesmen,
	interCompanyChallengeSectorsWithSalesmenHubspotId,
	smallClientRanges,
} from "../../config/ContactVariables";
import { HubspotContentsPost } from "../../types/HubspotContentsPost";

export interface ContactMessageForm {
	firstName: string;
	lastName: string;
	companyName: string;
	email: string;
	phone: string;
	content: string;
}

export interface RdvMessageForm {
	date: string | null;
	time: string | null;
}

interface ContactContextType {
	subject: ContactSubjectType | null;
	setSubject: (s: ContactSubjectType) => void;
	challengeType: ChallengeType | null;
	setChallengeType: (t: ChallengeType) => void;
	sector: ContactSector | null;
	setSector: (s: ContactSector) => void;
	nbUsers: number;
	setNbUsers: (n: number) => void;
	message: ContactMessageForm;
	setMessage: (m: ContactMessageForm) => void;
	rdv: RdvMessageForm;
	setRdv: (r: any) => void;
	postContact: (m: ContactMessageForm) => Promise<any>;
	getChallengeRange: () => string;
	posting: boolean,
}

const initialMessageValues: ContactMessageForm = {
	firstName: "",
	lastName: "",
	companyName: "",
	phone: "",
	email: "",
	content: "",
};

const initialRdvValues: RdvMessageForm = {
	date: null,
	time: null,
};

const ContactContext = createContext<ContactContextType>({
	subject: null,
	setSubject: () => null,
	challengeType: null,
	setChallengeType: () => null,
	sector: null,
	setSector: () => null,
	nbUsers: 1,
	setNbUsers: () => null,
	message: initialMessageValues,
	setMessage: () => null,
	rdv: initialRdvValues,
	setRdv: () => null,
	postContact: () => Promise.resolve(),
	getChallengeRange: () => "",
	posting: false,
});

const isSmallClient = (
	challengeType: ChallengeType | null,
	challengeRange: string
) =>
	challengeType === ChallengeType.companies ||
	(challengeType === ChallengeType.internal &&
		smallClientRanges.includes(challengeRange));

export const getSalesmanAddressFromContactDetails = (
	subject: ContactSubjectType | null,
	sector: ContactSector | null,
	challengeType: ChallengeType | null,
	challengeRange: string
): SalesmenAddress | null => {
	if (!subject && !sector) return null;
	switch (subject) {
		case ContactSubjectType.challenge:
			if (!sector) return SalesmenAddress.contact;
			const record = isSmallClient(challengeType, challengeRange)
				? interCompanyChallengeSectorsWithSalesmen
				: internalChallengeSectorsWithSalesmen;
			return record[sector];
		case ContactSubjectType.ao:
			return SalesmenAddress.capucine;
		case ContactSubjectType.partnership:
			return SalesmenAddress.contact;
		case ContactSubjectType.other:
			return SalesmenAddress.contact;
		case ContactSubjectType.state:
			return SalesmenAddress.state;
		default:
			return null;
	}
};

interface HubspotDetails {
	id: SalesmenHubspotId;
	offerType: string;
}

export const getHubspotsInformationsFromContactDetails = (
	subject: ContactSubjectType | null,
	challengeType: ChallengeType | null,
	sector: ContactSector | null,
	challengeRange: string
): HubspotDetails | null => {
	if (!subject && !sector) return null;
	switch (subject) {
		// Challenge and Partnership are the only types resulting in an Hubspot creation.
		case ContactSubjectType.challenge:
			let id = SalesmenHubspotId.capucine;
			const record = isSmallClient(challengeType, challengeRange)
				? interCompanyChallengeSectorsWithSalesmenHubspotId
				: internalChallengeSectorsWithSalesmenHubspotId;
			if (sector) {
				id = record[sector];
			}
			return {
				id,
				offerType: hubspotOfferTypes[challengeType || ChallengeType.companies],
			};
		case ContactSubjectType.partnership:
			return {
				id: SalesmenHubspotId.julia,
				offerType: "Partenaire",
			};
		default:
			return null;
	}
};

export function getProperSector(
	sector: ContactSector | undefined | null
): string | null {
	if (!sector) return null;
	if (sector === ContactSector.energies) {
		return "Energies";
	} else if (sector === ContactSector.loisirs) {
		return "Sport";
	} else if (sector === ContactSector.agenceConseil) {
		return "Agence conseil";
	} else if (sector === ContactSector.informatiqueEtTelecomsESN) {
		return "Informatique & Télécoms";
	} else if (sector === ContactSector.industrie) {
		return "Automobile & Métallurgie";
	} else if (sector === ContactSector.retailTextileHabillement) {
		return "Retail";
	} else {
		return sector;
	}
}

export const ContactContextProvider = ({ children }: PropsWithChildren) => {
	const [subject, setSubject] = useState<ContactSubjectType | null>(null);
	const [challengeType, setChallengeType] = useState<ChallengeType | null>(
		null
	);
	const [sector, setSector] = useState<ContactSector | null>(null);
	const [nbUsers, setNbUsers] = useState<number>(1);
	const [message, setMessage] =
		useState<ContactMessageForm>(initialMessageValues);
	const [rdv, setRdv] = useState<RdvMessageForm>(initialRdvValues);

	const [posting, setPosting] = useState<boolean>(false);

	const getChallengeRange = () =>
		contactChallengeRanges[challengeType || ChallengeType.city][nbUsers];

	async function postContact(messageValues: ContactMessageForm): Promise<any> {
		setPosting(true);
		if (
			subject === ContactSubjectType.ao ||
			subject === ContactSubjectType.other
		) {
			return sendEmailToContact(messageValues).then(() => setPosting(false));
		}
		if (
			subject === ContactSubjectType.partnership
		) {
			return sendEmailToContact(messageValues, false).then(() => setPosting(false));
		}
		return creteHubspotContact(messageValues)
			.then(() => sendEmailToContact(messageValues).then(() => setPosting(false)))
			.catch(Promise.reject);
	}

	async function creteHubspotContact(
		messageValues: ContactMessageForm
	): Promise<any> {
		const { firstName, lastName, email, content, phone, companyName } =
			messageValues;
		const challengeRange = getChallengeRange();
		const hubspotInformations = getHubspotsInformationsFromContactDetails(
			subject,
			challengeType,
			sector,
			challengeRange
		);

		if (!hubspotInformations) return Promise.reject();
		const { id: ownerId, offerType } = hubspotInformations;

		const sizeBracket = bracketSizes[challengeRange];
		const payload: HubspotContentsPost = {
			firstName,
			lastName,
			email,
			phoneNumber: phone,
			ownerId,
			companyName,
			content,
			// If this is a Partnership, we don't necessarily need the sector nor size bracket.
			sector: getProperSector(sector) || undefined,
			sizeBracket:
				subject === ContactSubjectType.partnership ? undefined : sizeBracket,
			offerType,
		};
		return POST_XHTTP("external/hubspot", payload);
	}

	function sendEmailToContact(messageValues: ContactMessageForm, sendToBiz = true) {
		const mailSubject = `Contact via le site Energic`;
		const challengeRange = getChallengeRange();
		const recipientEmail = getSalesmanAddressFromContactDetails(
			subject,
			sector,
			challengeType,
			challengeRange
		);

		const content = `
            Sujet: ${subject?.toString()}
			${
				challengeType
					? `Type de Challenge : ${readableChallengeType[challengeType]}`
					: ""
			}
            ${challengeType && sector ? `Secteur d'activité : ${sector}` : ""}
            ${
							challengeType
								? `Taille de la structure : ${
										contactChallengeRanges?.[
											challengeType ?? ChallengeType.internal
										]?.[nbUsers]
								  }`
								: ""
						}
            Nom: ${messageValues?.lastName}
            Prénom: ${messageValues?.firstName}
			Téléphone: ${messageValues?.phone?.replaceAll(" ", "")}
            Email: ${messageValues?.email}
            Message: ${messageValues?.content}
            Entreprise: ${messageValues?.companyName}
			Destinataire Energic: ${recipientEmail}
			
        `;

		let mailBody: MailBodyType = {
			subject: mailSubject,
			content,
			attachments: [],
		};

		return POST_XHTTP(
			`mail?${
				sendToBiz ? `&email=${encodeURIComponent(SalesmenAddress.biz)}` : ''
			}${
				recipientEmail ? `&email=${encodeURIComponent(recipientEmail)}` : ""
			}`,
			mailBody
		);
	}

	return (
		<ContactContext.Provider
			value={{
				subject,
				setSubject,
				challengeType,
				setChallengeType,
				sector,
				setSector,
				nbUsers,
				setNbUsers,
				message,
				setMessage,
				rdv,
				setRdv,
				postContact,
				getChallengeRange,
				posting,
			}}
		>
			{children}
		</ContactContext.Provider>
	);
};

export default ContactContext;
