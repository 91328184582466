import { ChallengeType } from "../types/BlogTypes";

export enum ContactSector {
	administrationPublique = "Administration publique",
	agenceConseil = "Agences études conseil & service aux entreprises",
	agroAlimentaire = "Agro-alimentaire",
	bailleurs = "Bailleurs",
	banqueAssurance = "Banque/Assurance",
	btp = "BTP",
	chimiePharma = "Chimie/Pharma",
	collectivite = "Collectivité",
	energies = "Énergies",
	immobilier = "Immobilier",
	industrie = "Industrie",
	loisirs = "Loisirs (Sport, tourisme & Culturel)",
	informatiqueEtTelecomsESN = "Informatique et Télécoms & ESN",
	retailTextileHabillement = "Retail, textile & habillement",
	superieur = "Supérieur",
	transportsLogistique = "Transports & Logistique",
}

export enum SalesmenAddress {
	capucine = "capucine@energic.io",
	ph = "ph@energic.io",
	alexandre = "alexandre@energic.io",
	mathieu = "mathieu@energic.io",
	charlotte = "charlotte@energic.io",
	maxence = "maxence@energic.io",
	contact = "contact@energic.io",
	biz = "biz@energic.io",
	state = "challengeetat@energic.io",
}

export enum SalesmenHubspotId {
	capucine = "683445716",
	ph = "683435490",
	julia = "681276395",
	alexandre = "1296811227",
	mathieu = "1296811228",
	charlotte = "1296811229",
	maxence = "1296811230",
}

export const internalChallengeSectorsWithSalesmen: Record<
	ContactSector,
	SalesmenAddress
> = {
	[ContactSector.administrationPublique]: SalesmenAddress.ph,
	[ContactSector.agenceConseil]: SalesmenAddress.mathieu,
	[ContactSector.agroAlimentaire]: SalesmenAddress.ph,
	[ContactSector.bailleurs]: SalesmenAddress.capucine,
	[ContactSector.banqueAssurance]: SalesmenAddress.ph,
	[ContactSector.btp]: SalesmenAddress.maxence,
	[ContactSector.chimiePharma]: SalesmenAddress.ph,
	[ContactSector.collectivite]: SalesmenAddress.ph,
	[ContactSector.energies]: SalesmenAddress.capucine,
	[ContactSector.immobilier]: SalesmenAddress.capucine,
	[ContactSector.industrie]: SalesmenAddress.maxence,
	[ContactSector.loisirs]: SalesmenAddress.ph,
	[ContactSector.informatiqueEtTelecomsESN]: SalesmenAddress.capucine,
	[ContactSector.retailTextileHabillement]: SalesmenAddress.ph,
	[ContactSector.superieur]: SalesmenAddress.ph,
	[ContactSector.transportsLogistique]: SalesmenAddress.charlotte,
};

export const interCompanyChallengeSectorsWithSalesmen: Record<
	ContactSector,
	SalesmenAddress
> = {
	[ContactSector.administrationPublique]: SalesmenAddress.alexandre,
	[ContactSector.agenceConseil]: SalesmenAddress.maxence,
	[ContactSector.agroAlimentaire]: SalesmenAddress.alexandre,
	[ContactSector.bailleurs]: SalesmenAddress.charlotte,
	[ContactSector.banqueAssurance]: SalesmenAddress.charlotte,
	[ContactSector.btp]: SalesmenAddress.maxence,
	[ContactSector.chimiePharma]: SalesmenAddress.mathieu,
	[ContactSector.collectivite]: SalesmenAddress.alexandre,
	[ContactSector.energies]: SalesmenAddress.charlotte,
	[ContactSector.immobilier]: SalesmenAddress.charlotte,
	[ContactSector.industrie]: SalesmenAddress.maxence,
	[ContactSector.loisirs]: SalesmenAddress.alexandre,
	[ContactSector.informatiqueEtTelecomsESN]: SalesmenAddress.mathieu,
	[ContactSector.retailTextileHabillement]: SalesmenAddress.mathieu,
	[ContactSector.superieur]: SalesmenAddress.mathieu,
	[ContactSector.transportsLogistique]: SalesmenAddress.maxence,
};

export const internalChallengeSectorsWithSalesmenHubspotId: Record<
	ContactSector,
	SalesmenHubspotId
> = {
	[ContactSector.administrationPublique]: SalesmenHubspotId.ph,
	[ContactSector.agenceConseil]: SalesmenHubspotId.mathieu,
	[ContactSector.agroAlimentaire]: SalesmenHubspotId.ph,
	[ContactSector.bailleurs]: SalesmenHubspotId.capucine,
	[ContactSector.banqueAssurance]: SalesmenHubspotId.ph,
	[ContactSector.btp]: SalesmenHubspotId.maxence,
	[ContactSector.chimiePharma]: SalesmenHubspotId.ph,
	[ContactSector.collectivite]: SalesmenHubspotId.ph,
	[ContactSector.energies]: SalesmenHubspotId.capucine,
	[ContactSector.immobilier]: SalesmenHubspotId.capucine,
	[ContactSector.industrie]: SalesmenHubspotId.maxence,
	[ContactSector.loisirs]: SalesmenHubspotId.ph,
	[ContactSector.informatiqueEtTelecomsESN]: SalesmenHubspotId.capucine,
	[ContactSector.retailTextileHabillement]: SalesmenHubspotId.ph,
	[ContactSector.superieur]: SalesmenHubspotId.ph,
	[ContactSector.transportsLogistique]: SalesmenHubspotId.charlotte,
};

export const interCompanyChallengeSectorsWithSalesmenHubspotId: Record<
	ContactSector,
	SalesmenHubspotId
> = {
	[ContactSector.administrationPublique]: SalesmenHubspotId.alexandre,
	[ContactSector.agenceConseil]: SalesmenHubspotId.maxence,
	[ContactSector.agroAlimentaire]: SalesmenHubspotId.alexandre,
	[ContactSector.bailleurs]: SalesmenHubspotId.charlotte,
	[ContactSector.banqueAssurance]: SalesmenHubspotId.charlotte,
	[ContactSector.btp]: SalesmenHubspotId.maxence,
	[ContactSector.chimiePharma]: SalesmenHubspotId.mathieu,
	[ContactSector.collectivite]: SalesmenHubspotId.alexandre,
	[ContactSector.energies]: SalesmenHubspotId.charlotte,
	[ContactSector.immobilier]: SalesmenHubspotId.charlotte,
	[ContactSector.industrie]: SalesmenHubspotId.maxence,
	[ContactSector.loisirs]: SalesmenHubspotId.alexandre,
	[ContactSector.informatiqueEtTelecomsESN]: SalesmenHubspotId.mathieu,
	[ContactSector.retailTextileHabillement]: SalesmenHubspotId.mathieu,
	[ContactSector.superieur]: SalesmenHubspotId.mathieu,
	[ContactSector.transportsLogistique]: SalesmenHubspotId.maxence,
};

export const salesmanCalendarUrl: Record<SalesmenAddress, string> = {
	[SalesmenAddress.capucine]: "https://meetings-eu1.hubspot.com/capucine-abele",
	[SalesmenAddress.ph]:
		"https://meetings-eu1.hubspot.com/paul-henri-vanthournout",
	[SalesmenAddress.maxence]: "https://meetings-eu1.hubspot.com/maxence-cloitre",
	[SalesmenAddress.mathieu]:
		"https://meetings-eu1.hubspot.com/mathieu-spaesbrugghe/mathieu",
	[SalesmenAddress.charlotte]:
		"https://meetings-eu1.hubspot.com/charlotte-dodergny",
	[SalesmenAddress.alexandre]:
		"https://meetings-eu1.hubspot.com/alexandre-leclercq",
	[SalesmenAddress.contact]:
		"https://meetings-eu1.hubspot.com/capucine-abele",
	[SalesmenAddress.biz]: "https://meetings-eu1.hubspot.com/capucine-abele",
	[SalesmenAddress.state]: "https://meetings-eu1.hubspot.com/capucine-abele",
};

export const contactChallengeRanges: Record<ChallengeType, string[]> = {
	[ChallengeType.companies]: [
		"Moins de 50",
		"De 50 à 150",
		"De 150 à 250",
		"Plus de 250",
	],
	[ChallengeType.internal]: [
		"Moins de 250",
		"De 250 à 500",
		"De 500 à 1000",
		"De 1000 à 5000",
		"Plus de 5000",
	],
	[ChallengeType.school]: [
		"Moins de 250",
		"De 250 à 500",
		"De 500 à 1000",
		"De 1000 à 5000",
		"Plus de 5000",
	],
	[ChallengeType.tenant]: [
		"Moins de 250",
		"De 250 à 500",
		"De 500 à 1000",
		"De 1000 à 5000",
		"Plus de 5000",
	],
	[ChallengeType.city]: [
		// should not be used yet. Added for typescript
		"Moins de 250",
		"De 250 à 500",
		"De 500 à 1000",
		"De 1000 à 5000",
		"Plus de 5000",
	],
};

export const smallClientRanges = [
	"Moins de 50",
	"De 50 à 150",
	"De 150 à 250",
	"Moins de 250",
	"Plus de 250",
	"De 250 à 500",
];

export const bracketSizes: Record<string, string> = {
	"Moins de 50": "< 50",
	"De 50 à 150": "50 à 150",
	"De 150 à 250": "150 à 250",
	"Moins de 250": "< 250",
	"Plus de 250": "250 à 500",
	"De 250 à 500": "250 à 500",
	"De 500 à 1000": "500 à 1 000",
	"De 1000 à 5000": "1 000 à 2 000",
	"Plus de 5000": "2 000 à 5 000",
};

export const readableChallengeType: Record<ChallengeType, string> = {
	[ChallengeType.companies]: "Inter-entreprises",
	[ChallengeType.internal]: "Interne",
	[ChallengeType.school]: "Étudiant",
	[ChallengeType.tenant]: "Locataire",
	[ChallengeType.city]: "Citoyen",
};

export const hubspotOfferTypes: Record<ChallengeType, string> = {
	[ChallengeType.companies]: "Inter-entreprise",
	[ChallengeType.internal]: "Collaborateur",
	[ChallengeType.school]: "Université",
	[ChallengeType.tenant]: "Locataire",
	[ChallengeType.city]: "Citoyen",
};
